import { Primitive } from '@/types/helpers/Primitive.ts';

export type IRequestSearchFilter<T extends Record<string, Primitive> | undefined = undefined> =
  (T extends undefined
    ? Record<
        string,
        {
          filterType: IRequestSearchFilterTypeEnumKeys;
          value: Primitive;
        }
      >
    : {
        [K in keyof T]: {
          filterType: IRequestSearchFilterTypeEnumKeys;
          value: T[K];
        };
      }) & { isFilter: true; unspecified?: Primitive[] };

export type IRequestSearchFilterTypeEnumKeys = keyof typeof IRequestSearchFilterTypeEnum;
export enum IRequestSearchFilterTypeEnum {
  'equal' = ':',
  'less' = '<',
  'more' = '>',
}
